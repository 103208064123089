import {
  IonButton,
  IonCol,
  IonContent,
  IonFabButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonPage,
  IonRouterLink,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { arrowBackOutline, arrowForwardCircle, enterOutline, logoApple, logoGithub, logoInstagram, logoSkype, send, settingsSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { auth, db, rtdb, timestamp } from '../services/firebase';
import { generateAnonName, matchRoomUrl } from '../services/utilities';
import './Home.css';
import {Adsense} from '@ctrl/react-adsense';
import discordIcon from '@iconify/icons-simple-icons/discord';
import Icon from '@iconify/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Home: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState('');
  const [roomLink, setRoomLink] = useState('');
  const [valid, setValid] = useState(true);

  let history = useHistory();

  // Sign in anonymously before finishing loading page content
  useEffect(() => {
    const authUnsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUserId(user.uid);
        setLoading(false);
      } else {
        const credential = await auth.signInAnonymously();
        await db.collection('users').doc(credential.user?.uid).set({
          name: generateAnonName(),
        });
      }
    });

    return () => {
      authUnsubscribe();
    };
  }, []);

  // Populate both Firestore and RealTimeDB before navigating to room on create
  const createRoom = async () => {
    // Firestore preparations
    const roomId = await db.collection('rooms').add({
      createdAt: timestamp,
      ownerId: userId,
      requests: [],
      state: { time: 0, isPlaying: false },
    });
    await db.collection('playlists').doc(roomId.id).set({
      createdAt: timestamp,
      url: 'https://www.youtube.com/watch?v=SMKPKGW083c',
    });
    await db.collection('states').doc(roomId.id).set({
      isPlaying: false,
      time: 0,
    });

    // RealTimeDB preparations
    await rtdb.ref('/rooms/' + roomId.id).set({ userCount: 0 });
    await rtdb.ref('/available/' + roomId.id).set({ name: 'Room Name', createdAt: new Date().toISOString() });
    const path = '/room/' + roomId.id;

    return history.push(path); // Navigate to room
  };

  const showError = () => {
    return <div style={{ color: 'red', fontSize: '14px' }}>Invalid link</div>;
  };

  // Validate URL and join the room
  const joinRoom = async () => {
    if (matchRoomUrl(roomLink)) {
      const roomId = roomLink.split('/')[roomLink.split('/').length - 1];
      const doc = await db.collection('rooms').doc(roomId).get();
      if (doc.exists) {
        setValid(true);
        return history.push('/room/' + roomId);
      }
    }

    setValid(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar> 
          <IonImg src='/assets/araari-logo1.png'  onClick={Home} class='logo-center'>Araari</IonImg>
        </IonToolbar>
      </IonHeader>
      <IonContent class="home-content">
   
      {/* {loading ? (
                    <IonContent className="ion-padding">Please Wait...</IonContent>
                  ) : (  */}
          
        
          <IonGrid class="home-grid">
            <IonRow>
              <IonCol size="12" sizeLg="6" class="intro-col">
                <IonRow>
                  <IonCol class="intro-header">Watch videos online with friends!</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol class="support-col">--Watch YouTube Videos -- </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol class="request-col">
                    <a href="" target="_blank" rel="noopener noreferrer">
                      Request more options
                    </a>
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol size="12" sizeLg="6">
                <IonRow>
                  <IonCol class="first-step-col">Step 1:</IonCol>
                </IonRow>
                  
                <IonRow>
              
                  <IonCol size="12" class="create-col">
                  {/* {loading ? ( */}
                    {/* <IonContent className="ion-padding">Please Wait...</IonContent> */}
                  {/* // ) : (  */}
                    <IonButton onClick={createRoom} class="create-room">
                      Create Room
                    </IonButton>
                  {/* //  )} */}
                  </IonCol>
                </IonRow>
             
                <IonRow>
                  <IonCol size="12">Step 2:</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" class="share-col">
                    Share the link.
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12">OR</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" class="join-col">
                    Join a room:
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" class="paste-col">
                    <IonToolbar class="paste-toolbar">
                      <IonInput onIonChange={(e) => setRoomLink(e.detail.value!)} placeholder="Paste room link" class="paste-input"></IonInput>
                      <IonFabButton slot="end" size="small" disabled={roomLink === ''} onClick={joinRoom} class="paste-button">
                        <IonIcon icon={enterOutline}></IonIcon>
                      </IonFabButton>
                    </IonToolbar>
                    {valid ? <></> : showError()}
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
            
            
  </IonGrid>


  {/* <IonGrid >
    <IonRow>
        <div >
          <h1>Animal Facts</h1>

          <h2>Rhinoceros</h2>
          <img
            alt="rhino standing near grass"
            src="https://images.unsplash.com/flagged/photo-1556983257-71fddc36bc75?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80"
          />
          <p>
            The rhinoceros gets its name from one of its most notable features: its horns. The word rhinoceros come from
            the Greek words rhino meaning “nose” and ceros meaning “horn.” The number of horns that a rhino has varies
            on the species. The two African species (the black rhino and the white rhino) and the Sumatran rhino have
            two horns, while the Javan rhino and one-horned rhino have one horn.
          </p>

          <h2>Sea Turtle</h2>
          <img
            alt="brown sea turtle in water"
            src="https://images.unsplash.com/photo-1573551089778-46a7abc39d9f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80"
          />
          <p>
            Sea turtles are characterized by a large, streamlined shell and non-retractile head and limbs. Unlike other
            turtles, sea turtles cannot pull their limbs and head inside their shells. Their limbs are flippers that are
            adapted for swimming, so they are vulnerable while on land.
          </p>

          <h2>Giraffe</h2>
          <img
            alt="giraffe sticking its tongue out"
            src="https://images.unsplash.com/photo-1577114995803-d8ce0e2b4aa9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80"
          />
          <p>
            Giraffes are known for their long, tall appearance. They have a small hump on their back like a camel and
            have a spotted pattern similar to that of a leopard. Because of the combination of these features, some
            people called the giraffe a “camel-leopard.” That’s where the giraffe’s species name “camelopardalis” comes
            from.
          </p>

          <h2>Elephant</h2>
          <img
            alt="two grey elephants on grass plains during sunset"
            src="https://images.unsplash.com/photo-1564760055775-d63b17a55c44?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1754&q=80"
          />
          <p>
            Elephants are the largest existing land animal, with massive bodies, large ears, and long trunks. Elephants’
            long trunks are multifunctional. They are used to pick up objects, trumpet warnings, greet other elephants,
            or suck up water for drinking or bathing.
          </p>

          <h2>Dolphin</h2>
          <img
            alt="black and white dolphin in water"
            src="https://images.unsplash.com/photo-1607153333879-c174d265f1d2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80"
          />
          <p>
            Dolphins range in color depending on the species, from white, pearl, and pink to darker shades of brown,
            gray, blue, and black. They have smooth skin, flippers, and a dorsal fin. They have a long, slender snout
            with about 100 teeth and a streamlined body. They have a single blowhole on top of their head, which has a
            flap that opens to reveal a pair of nostrils. The dolphin uses these nostrils for breathing when it
            surfaces.
          </p>
        </div>
        </IonRow>
      </IonGrid> */}
          
                  {/* // )} */}

                  

{/* <IonGrid class="about-grid" >
        <IonRow>
    
          <IonCol>
            <span>Any feedback, questions, or issues? </span>
            <span role="img" aria-label="VEPLAY">
            
            </span>
          </IonCol>
        </IonRow>
        <IonRow class="externals-row">
          <IonCol size="3"></IonCol>
          <IonCol size="3">
            <IonRouterLink href="https://github.com/shuang854/VEPLAY" target="_blank">
            <IonButton size="small">
       Instagram
        <IonIcon slot="start" icon={logoInstagram}></IonIcon>
      </IonButton>

            </IonRouterLink>
          </IonCol>
          <IonCol size="3">
          <IonRouterLink href="https://github.com/shuang854/VEPLAY" target="_blank">
            <IonButton size="small">
       Telegram
        <IonIcon slot="start" icon={logoSkype}></IonIcon>
      </IonButton>

            </IonRouterLink>
          </IonCol>
          <IonCol size="3"></IonCol>
        </IonRow>
        </IonGrid> */}
        <IonGrid class="footer-grid">
        <IonRow >
        
          <IonCol size="3" class="support-col" >
            <IonRouterLink href="#"  target="_blank">
           <IonText > About</IonText>
            </IonRouterLink>
          </IonCol>
          <IonCol size="3"  class="support-col">
            <IonRouterLink href="#"  target="_blank">
            {/* <Icon icon={discordIcon} className="about-icons"></Icon> */}
            <IonText>Contact Us</IonText>
            </IonRouterLink>
          </IonCol>
          <IonCol size="3"  class="support-col" >
            <IonRouterLink href="#"  target="_blank">
            {/* <Icon icon={discordIcon} className="about-icons"></Icon> */}
            <IonText>Privacy Policy</IonText>
            </IonRouterLink>
          </IonCol>
          <IonCol size="3" class="support-col" >
            <IonRouterLink href="#"  target="_blank">
            {/* <Icon icon={discordIcon} className="about-icons"></Icon> */}
            <IonText>Terms & Conditions</IonText>
            </IonRouterLink>
          </IonCol>
        </IonRow>
        </IonGrid>
  
         
      </IonContent>

    </IonPage>
  );
};

export default Home;
